const descriptor = {
    amount: {
        type: "number",
        required: true,
        message: "Amount is require and must be a valid number",
        validator: (rule, value) => value > 0,
        transform(value) {
            return parseFloat(value)
        },
    },
    payment_method: {
        type: "string",
        required: true,
        message: lifeline_donation.required_strings.payment_method,
    },
    reucrring: { type: 'enum', enum: [true, false] },
    billing_fields: {
        type: 'object',
        required: true,
        fields: {
            first_name: { type: "string", required: true, message: lifeline_donation.required_strings.first_name },
            last_name: { type: "string", required: true, message: lifeline_donation.required_strings.last_name },
            // phone: { type: "string", required: true, message: 'Phone is required and must be a valid phone number' },
            address: { type: "string", required: true, message: lifeline_donation.required_strings.address },
            email: { type: "email", required: true, message: lifeline_donation.required_strings.email },
        }
    },

};

export default descriptor;
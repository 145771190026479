<template>
  <el-dialog
    :visible.sync="showModal"
    :width="config.width"
    :custom-class="`donation-style-${(dstyle || 1)} ${config.wrapper_class}`"
    :modal-append-to-body="true"
    :append-to-body="true"
    @close="
      $emit('close');
    "
    :class="`wpcm-wrapper wpcm-wrapper-${dtype}`"
    :show-close="false"
    :destroy-on-close="true"
  >
    <span class="closep" slot="title" @click="showModal = false"
      ><i class="el-icon-close"></i
    ></span>
    <div class="dialog-content" v-loading="loading">
      <modal-template v-if="components" :config="config" :currentStep="step">
        <template v-for="(comp, index) in components">
          <modal-view :key="index" :comp="comp" :slot="comp.slot"></modal-view>
        </template>
      </modal-template>

      <div class="mt-4 text-center single-proced-btn" style="display: none;">
        <el-button
          :class="[config.proceed_classes || 'proceed btn']"
          @click="next()"
          v-if="step < config.steps"
          >{{ config.proceed && "Proceed" }}</el-button
        >
        <el-button
          :class="[config.proceed_classes || 'proceed btn']"
          @click="submit()"
          v-else
          >{{ config.donate_now }}</el-button
        >
        <el-button
          :class="[config.proceed_classes || 'proceed btn']"
          @click="back()"
          v-if="step > 1 && config.show_back_btn"
          >{{ config.back_text && "Go Back" }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
const $ = jQuery;
const { mapState, mapMutations } = window.Vuex;
import descriptor from '../utils/rules'
import ModalView from "./ModalView.vue";
import ModalTemplate from "./ModalTemplate.vue";

export default {
  name: "Modal",
  components: { ModalView, ModalTemplate },
  props: {
    id: {
      type: Number,
      default: 0
    },
    dstyle: {
      type: [Number, String],
      default: 1
    },
    dtype: {
      type: String,
      default: 'general' // general or postType
    }
  },
  data() {
    return {
      loader: "",
    };
  },
  computed: {
    ...mapState(["loading", "components", "config", "step"]),
    showModal: {
      get() {
        return this.$store.state.showModal;
      },
      set(val) {
        this.$store.commit("setValue", { key: "showModal", val });
      },
    },
  },
  watch: {
    loading(val) {
      if (!val && this.loader) {
        this.loader.close();
      }
    },
  },
  mounted() {
    console.log(this.dtype)
    this.$store.dispatch("getData", { vm: this });
    if (this.showModal) {
    }
    this.loader = this.$loading({
      lock: true,
    });
  },
  methods: {
    ...mapMutations(["next", "back", "reset"]),
    submit() {
      const { state } = this.$store;
      this.$store
        .dispatch("validate", { rules: descriptor, fields: state })
        .then((res) => {
          this.$store.dispatch('submit', {vm: this})
        })
        .catch((fields, errors) => {
          this.validation_catch(fields, errors);
        });
      // this.$store.dispatch("submit", { vm: this });
    },
    validation_catch(fields, errors) {
      _.each(fields, (field) => {
        setTimeout(() => {
          this.$notify.error({
            title: "Error",
            message: field[0].message,
            offset: 40,
          });
        }, 300);
      });
    },
  },
};
</script>

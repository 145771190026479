var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show_progress,
            expression: "show_progress"
          }
        ],
        staticClass: "wpcm-radial-progress-bar"
      },
      [
        _vm.collected >= 0 && _vm.needed
          ? _c("div", { staticClass: "circular" }, [
              _c("input", {
                ref: "knob",
                staticClass: "knob",
                attrs: {
                  "data-fgColor": _vm.color_scheme,
                  "data-bgColor": "#dddddd",
                  "data-thickness": ".10",
                  readonly: ""
                },
                domProps: {
                  value: ((_vm.collected / _vm.needed) * 100).toFixed(1)
                }
              }),
              _vm.strings
                ? _c("span", [_vm._v(_vm._s(_vm.strings.completed))])
                : _vm._e()
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.show_collection
      ? _c("div", { staticClass: "wpcm-amount-collected" }, [
          _vm.currency_position == "left"
            ? _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(_vm._s(_vm.collected))
              ])
            : _vm.currency_position == "right"
            ? _c("span", { staticClass: "amount-return" }, [
                _vm._v(_vm._s(_vm.collected)),
                _c("i", [_vm._v(_vm._s(_vm.symbol))])
              ])
            : _vm.currency_position == "left_s"
            ? _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(" " + _vm._s(_vm.collected))
              ])
            : _vm.currency_position == "right_s"
            ? _c("span", { staticClass: "amount-return" }, [
                _vm._v(_vm._s(_vm.collected) + " "),
                _c("i", [_vm._v(_vm._s(_vm.symbol))])
              ])
            : _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(_vm._s(_vm.collected))
              ]),
          _vm.strings
            ? _c("span", [_vm._v(_vm._s(_vm.strings.collection))])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.show_collection
      ? _c("div", { staticClass: "wpcm-amount-needed" }, [
          _vm.currency_position == "left"
            ? _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(_vm._s(_vm.needed))
              ])
            : _vm.currency_position == "right"
            ? _c("span", { staticClass: "amount-return" }, [
                _vm._v(_vm._s(_vm.needed)),
                _c("i", [_vm._v(_vm._s(_vm.symbol))])
              ])
            : _vm.currency_position == "left_s"
            ? _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(" " + _vm._s(_vm.needed))
              ])
            : _vm.currency_position == "right_s"
            ? _c("span", { staticClass: "amount-return" }, [
                _vm._v(_vm._s(_vm.needed) + " "),
                _c("i", [_vm._v(_vm._s(_vm.symbol))])
              ])
            : _c("span", { staticClass: "amount-return" }, [
                _c("i", [_vm._v(_vm._s(_vm.symbol))]),
                _vm._v(_vm._s(_vm.needed))
              ]),
          _vm._v(" "),
          _vm.strings
            ? _c("span", [_vm._v(_vm._s(_vm.strings.target))])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }